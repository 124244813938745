import { storeToRefs } from 'pinia'

import { useUserBandSetStore } from '~/stores/userBandSet'

import type { PictureSize, PictureTarget } from '~/types/Picture'
import type { UserKind } from '~/types/UserKind'

export function useGetProfilePicture() {
  const {
    public: { USER_UPLOADED_IMAGE_BASE_URL },
  } = useRuntimeConfig()
  const { $pinia } = useNuxtApp()

  const { list: BAND_LIST } = storeToRefs(useUserBandSetStore($pinia))
  const { GET_BAND_INDEX_FROM_ID } = useUserBandSetStore($pinia)

  /**
   * Gets the specified band's profile picture.
   * @param id - The id of the band.
   * @param pictureType - The type and size of the picture you're getting.
   * @param cacheId - The cache id of the image. When provided, it invalidates the cache of the image by appending a uid to the url.
   * @returns the URL of the band's profile picture.
   */
  function getBandProfilePictureForId<T extends PictureTarget>(
    id: number,
    pictureType: { target?: T; size?: PictureSize<T> },
    cacheId?: number,
  ) {
    const target = pictureType.target ?? 'profile_picture'
    const band = BAND_LIST.value[GET_BAND_INDEX_FROM_ID(id)]

    if (band && band[`has_${target}` as const]) {
      const url = getProfilePictureUrl({
        kind: 'band',
        slug: band.slug,
        size: pictureType.size ?? '400_400',
        target,
      })

      return cacheId ? `${url}?cacheId=${cacheId}` : url
    }
  }

  /**
   * Gets the influencer's profile picture by slug.
   * @param slug - The slug of the influencer.
   * @param pictureType - The type and size of the profile picture you're getting.
   * @param cacheId - The cache id of the image. When provided, it invalidates the cache of the image by appending a uid to the url.
   * @returns the URL of the influencer's profile picture.
   */
  function getInfProfilePictureForSlug<T extends PictureTarget>(
    slug: string,
    {
      target,
      size,
    }: {
      target?: T
      size?: PictureSize<T>
    },
    cacheId?: number,
  ) {
    const url = getProfilePictureUrl({
      kind: 'influencer',
      slug,
      size: size ?? '400_400',
      target: target ?? 'profile_picture',
    })

    return cacheId ? `${url}?cacheId=${cacheId}` : url
  }

  /**
   * Gets any entity's profile pictures (e.g. band, influencer, etc.).
   * @param pictureOptions - The options of the profile picture you're getting.
   * @returns the URL of the profile picture
   */
  function getProfilePictureUrl<T extends PictureTarget>({
    kind,
    target,
    size,
    slug,
  }: {
    kind: UserKind
    target: T
    size: PictureSize<T>
    slug?: string | undefined
  }) {
    return slug
      ? `${USER_UPLOADED_IMAGE_BASE_URL}${kind}/${slug}/${target}/${size}`
      : `${USER_UPLOADED_IMAGE_BASE_URL}${kind}/${target}/${size}`
  }

  return {
    getBandProfilePictureForId,
    getInfProfilePictureForSlug,
    getProfilePictureUrl,
  }
}
